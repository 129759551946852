import React from "react";
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Container,
  Row,
  Col, Card, CardBody, CardTitle, CardText
} from "reactstrap";

class WhyDescription extends React.Component {
  render() {
    const { t } = this.props;
    return (
        <>
        <section className="section section-lg-top section-shaped">
          <div className="shape shape-style-1 bg-gradient-info">
          </div>
          <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <h1 className="display-3 text-white">
                {/*Every great endeavour starts with <i>why</i>*/}
                Why?</h1>
              <h2 className="text-white">
                We believe in the creative power of healthy debate and exchange of ideas.
                {/*We believe healthy debate and exchange of ideas are the core of progress and of our democracies.*/}
              </h2>
            </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 3500 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="3500 0 3500 100 0 100" />
            </svg>
          </div>
        </section>
        <Container className="pb-5 mt-2">
            <Row>
            <Col>
              <Card className="card-lift--hover shadow border-0 ">
                <CardBody>
                  {/*<CardTitle><h5>Every great endeavour starts with <i>why</i></h5></CardTitle>*/}
                  <CardText>
                    <h5>Every great endeavour starts with <i>why</i></h5>
                    <p>
                    We all experienced, at least once in our life, the creative power of the exchange of ideas between two or
                    more human beings. It can take many forms, a podcast, a book, an exchange of letters, a tv debate, a forum
                    or comment section discussion, but there is something magical about two minds with different opinions learning
                    from each other and generating new valuable ideas in the process.
                  </p>
                    <p >
                      We believe that healthy debate and exchange of ideas are at the core of progress and of our democracies.
                      Discussions in which everybody is genuinely interested and listens to each other spark new ideas and find novel solutions to old conflicts. Unfortunately, this is rarely  happening on the internet right now: polarisation, extreme ideas and the crisis of traditional journalism are in the way. We believe we can play a role in fixing this.
                    </p>
                    <h5>
                      Continues with a <i>how</i>
                    </h5>
                    <p>
                      Humans can join forces with AI to achieve a productive and healthy discourse on the internet. We envision that AI will help humans expand and accelerate our thoughts and arguments, in a powerful combination.
                    </p>
                    <h5>
                      And becomes concrete with a <i>what</i>
                    </h5>
                    <p className={"mb-0"}>
                      Our product is a comment section moderator software based on AI that filters toxic comments, confront readers with opinion of opposing views, helps find constructive arguments and novel ideas. All this, while providing savings and added value to newspapers, allowing them to focus on producing quality journalism.
                    </p>
                  </CardText>
                </CardBody>
              </Card>
              </Col>
            </Row>
            </Container>
      </>
    );
  }
}

// export default withTranslation('why')(WhyDescription);
export default WhyDescription;
