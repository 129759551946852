import {Card, CardBody} from "reactstrap";
import React from "react";
import LabelBadge from "./LabelBadge.component";

class Comment extends React.PureComponent {

    static defaultProps = {
        bigText: false
    };

   render() {
       const commentStyle = {};
       if(this.props.bigText) {
           commentStyle.fontSize = 28;
       }

       const labelStyle = {};
       if(this.props.bigText) {
           labelStyle.fontSize = 34;
       }

        return (
            <Card className="shadow mt-3">
                <CardBody className="pb-3">
                    <p className="description mb-n1">
                        <div style={commentStyle} dangerouslySetInnerHTML={{__html: this.props.comment.commentBody
                                || "TEMP TEXT"}} />
                    </p>
                        <div className="mt-2" style={labelStyle}>
                        {this.props.taskNames.map((taskName) => {
                            return (<><LabelBadge label={this.props.comment[taskName + "_label"]} /> &nbsp; </>);
                        })}
                        </div>

                </CardBody>
            </Card>
        );
    }
}

export default Comment;
