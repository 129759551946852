import React from "react";
import { Link } from "react-router-dom";

import { withTranslation } from 'react-i18next';

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import DropdownLanguage from "./DropdownLanguage.component.js"

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  DropdownItem,
  DropdownButton
} from "reactstrap";

class CustomNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              
              <NavbarBrand 
                // className="mr-lg-5"
                to="/" 
                tag={Link}>
                <img
                  alt="Artivon"
                  style={{height: "45px"}}
                  src={require("../../assets/img/brand/logo_white.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="Artivon"
                          src={require("../../assets/img/brand/logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center mx-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/demo"
                    >
                      <span className="nav-link-inner--text">
                        Demo
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/who-we-are"
                    >
                      <span className="nav-link-inner--text">
                        {t(["who_we_are", "Who we are"])}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        className="nav-link-icon"
                        href="/technology"
                    >
                      <span className="nav-link-inner--text">
                        {t(["the_technology", "The technology"])}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/contact"
                    >
                      <span className="nav-link-inner--text">
                        {t(["contact_us", "Contact us"])}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-block d-lg-none">
                    <NavLink
                      className="nav-link-icon"
                      href="/portal/signup/"
                      target="_blank"
                    >
                      <span className="nav-link-inner--text">
                        {t(["sign_up", "Sign up"]).toUpperCase()}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-block d-lg-none">
                    <NavLink
                      className="nav-link-icon"
                      href="/portal/signin/"
                      target="_blank"
                    >
                      <span className="nav-link-inner--text">
                        {t(["sign_in", "Sign in"]).toUpperCase()}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-none d-lg-block">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="/portal/signup/"
                      target="_blank"
                    >
                      <span className="nav-link-inner--text">
                        {t(["sign_up", "Sign up"])}
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="d-none d-lg-block">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="/portal/signin"
                      target="_blank"
                    >
                      <span className="nav-link-inner--text">
                        {t(["sign_in", "Sign in"])}
                      </span>
                    </Button>
                  </NavItem>
                  <NavItem className="mt-2 mt-lg-0 d-lg-block">
                    <DropdownLanguage />
                  </NavItem>
                </Nav>

              </UncontrolledCollapse>

            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default withTranslation("navbar")(CustomNavbar);
