import React from "react";

import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

import nico_vonau_img from "./../../assets/img/people/nico_vonau_color.jpg"
import luca_campanella_img from "./../../assets/img/people/luca_campanella_color.jpg"
import alessandro_artoni_img from "./../../assets/img/people/alessandro_artoni_color.jpg"


class WhoWeAreContent extends React.Component {
  state = {
    people: [
        {
            id: "nico_vonau",
            img_path : nico_vonau_img,
            github: "vonau",
            linkedin: "nicovonau"
        },
        {
            id: "luca_campanella",
            img_path : luca_campanella_img,
            github: "lucacampanella",
            linkedin: "luca-campanella-132922197"
        },
        {
            id: "alessandro_artoni",
            img_path : alessandro_artoni_img,
            github: "AlessandroArtoni",
            linkedin: "alessandro-artoni-poviglio"
        }]
    };

  render() {
    const { t } = this.props;
    return (
      <>
      <section className="section section-lg-top section-shaped">
          <div className="shape shape-style-1 bg-gradient-info">
          </div>

          <Row className="justify-content-center text-center mb-4">
            <Col lg="8">
                <h1 className="display-3 text-white">
                    {t("whoweareContent.title")}
                </h1>
                <h3 className="text-white">
                    {t("whoweareContent.subtitle")}
                </h3>
            </Col>
            </Row>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <Container className="pb-5">
            <Row>
                
            {this.state.people.map((person) => {
                return (<Col className="mb-5 mb-lg-0" lg="4" md="6">
                            <div className="px-4">
                            <img
                                alt="..."
                                className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                                src={person.img_path}
                                style={{ width: "200px" }}
                            />
                            <div className="pt-4 text-center">
                                <h5 className="title">
                                <span className="d-block mb-1">{t("whoweareContent.people."+person.id+".name")}</span>
                                <small className="h6 text-muted">{t("whoweareContent.people."+person.id+".role")}</small>
                                </h5>
                                <p>
                                    {t("whoweareContent.people."+person.id+".card_description.1")}
                                    <br/>
                                    {t("whoweareContent.people."+person.id+".card_description.2")}
                                </p>
                                <div className="mt-3">
                                <Button
                                    className="btn-icon-only rounded-circle ml-1"
                                    color="primary"
                                    href={"https://www.linkedin.com/in/"+ person.linkedin}
                                    target="_blank"
                                >
                                    <i className="fa fa-linkedin" />
                                </Button>
                                <Button
                                    className="btn-icon-only rounded-circle"
                                    color="primary"
                                    href={"https://github.com/"+ person.github}
                                    target="_blank"
                                >
                                    <i className="fa fa-github" />
                                </Button>
                                </div>
                            </div>
                            </div>
                        </Col>)
                    })}    
            
            {/* 
            <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="px-4">
                <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require(`./../../assets/img/people/nico_vonau_color.jpg`)}
                    style={{ width: "200px" }}
                />
                <div className="pt-4 text-center">
                    <h5 className="title">
                    <span className="d-block mb-1">Nico Vonau</span>
                    <small className="h6 text-muted">CEO</small>
                    </h5>
                    <p>
                    Nico has been introducing various cutting-edge IoT technologies to different industries in Switzerland.
                    <br />
                    He has a Masters degree in Mechanical Engineering from the ETH Zürich.
                    </p>
                    <div className="mt-3">
                    <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="primary"
                        href="https://www.linkedin.com/in/nicovonau/?originalSubdomain=ch"
                        target="_blank"
                    >
                        <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="primary"
                        href="https://github.com/vonau"
                        target="_blank"
                    >
                        <i className="fa fa-github" />
                    </Button>
                    </div>
                </div>
                </div>
            </Col>
            <Col className="mb-5 mb-lg-0" lg="4" md="6">
                <div className="px-4">
                <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require(`./../../assets/img/people/luca_campanella_color.jpg`)}
                    style={{ width: "200px" }}
                />
                <div className="pt-4 text-center">
                    <h5 className="title">
                    <span className="d-block mb-1">Luca Campanella</span>
                    <small className="h6 text-muted">
                        CTO
                    </small>
                    </h5>
                    <p>
                        Luca is the creator of the ARTIVON Natural Language Processing model and soon
                        working as a research assistant at ETH to tackle various NLP problems.
                        <br />
                        He has a Masters degree in Computer Science from the ETH Zürich.
                    </p>
                    <div className="mt-3">
                    <Button
                        className="btn-icon-only rounded-circle ml-1"
                        color="primary"
                        href="https://www.linkedin.com/in/luca-campanella-132922197/"
                        target="_blank"
                    >
                        <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="primary"
                        href="https://github.com/lucacampanella"
                        target="_blank"
                    >
                        <i className="fa fa-github" />
                    </Button>
                    </div>
                </div>
                </div>
            </Col>
            <Col className="mb-5 mb-lg-0" lg="4" md="12">
                <div className="px-4">
                <img
                    alt="..."
                    className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                    src={require(`./../../assets/img/people/alessandro_artoni_color.jpg`)}
                    style={{ width: "200px" }}
                />
                <div className="pt-4 text-center">
                    <h5 className="title">
                    <span className="d-block mb-1">Alessandro Artoni</span>
                    <small className="h6 text-muted">Solution Architect</small>
                    </h5>
                    <p>
                    Alessandro has been implementing large scale end-to-end IT projects as a Technical Solution Consultant in Italy.
                    <br />
                    He has a Masters degree in Computer Science Engineering from Politecnico di Milano.
                    </p>
                    <div className="mt-3">
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="primary"
                        href="https://www.linkedin.com/in/alessandro-artoni-poviglio/"
                        target="_blank"
                    >
                        <i className="fa fa-linkedin" />
                    </Button>
                    <Button
                        className="btn-icon-only rounded-circle"
                        color="primary"
                        href="https://github.com/AlessandroArtoni"
                        target="_blank"
                    >
                        <i className="fa fa-github" />
                    </Button>
                    </div>
                </div>
                </div>
            </Col>
             */}</Row>
            </Container>
      </>
    );
  }
}

export default withTranslation("whoweare")(WhoWeAreContent);


