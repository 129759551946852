import React from "react";
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

class TechnologyDescription extends React.Component {
  render() {
    const { t } = this.props;
    return (
        <>
        <section className="section section-lg-top section-shaped">
          <div className="shape shape-style-1 bg-gradient-info">
          </div>
          <Row className="justify-content-center text-center mb-3">
            <Col lg="8">
              <h1 className="display-3 text-white">{t('technologyContent.title')}</h1>
            </Col>
            </Row>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
        <Container className="pb-5 mt-3">
            <Row>
            <Col>
              <p>
              <Trans i18nKey="technologyContent.body.1" ns="technology">
                The technology was developed as a Master Thesis at <b>ETH Zürich</b> in the Media Technology Center
              </Trans>
              (<a href="https://mtc.ethz.ch/" target="_blank" rel="noopener noreferrer">MTC</a>).
              </p>
              <p>
                <Trans i18nKey="technologyContent.body.2" ns="technology">
                  It is based on recent developments in Deep Learning and <b>Natural Language Processing</b>.
                  Its components allow to leverage just a few labelled examples and still have human comparable performance. The 
                </Trans>
                <a href="https://github.com/google-research/bert" target="_blank" rel="noopener noreferrer"> BERT </a> 
                <Trans i18nKey="technologyContent.body.3" ns="technology">
                  transformer encoder network serves as the base model to output classifications. Starting from a pre-trained model helps building a robust and specialised language model. On top of this semi-supervised learning and transfer learning techniques are added.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="technologyContent.body.4" ns="technology">
                  The approach was <b>tested on a dataset coming from the New York Times</b>, achieving human-like or better-than-human accuracy.",
                </Trans>
              </p>
              <p>
                <Trans i18nKey="technologyContent.body.5" ns="technology">
                  The generality of the technology allows to apply it to the main european languages. For now we support, or plan to support soon, <b>English, German, Italian, French and Spanish</b>
                </Trans>
              </p>
            </Col>
            </Row>
            </Container>
      </>
    );
  }
}

export default withTranslation('technology')(TechnologyDescription);
