import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';

import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import SelfWritingTextSolidBackgroundNoToxicity from "./SelfWritingTextSolidBackgroundNoToxicity.component";
import LabelsDisplayerSolidBackgroundNoToxicity from "./LabelsDisplayerSolidBackgroundNoToxicity.component";

const commentsData = [
    {
        "id": "5",
        "text": "An outstanding article, thanks for your insight.",
        "labels": ["positive", "for"],
    },
    {
        "id": "10",
        "text" : "You are wrong. Our economy is doing worse than ever.",
        "labels": ["negative", "against", "economics_and_finance"],
    },
    {
        "id": "15",
        "text" : "And Mr. Wu just figured this out?",
        "labels": ["sarcastic"],
    },
    {
        "id": "20",
        "text": "This proves how Republicans have bad intentions.",
        "labels": ["negative", "for", "liberal"],
    },
    {
        "id": "25",
        "text": "Any type of comment",
        "labels": ["custom"],
    }
];

class LandingSolidBackgroundNoToxicity  extends Component {
  constructor(){
      super();
      this.handleChange = this.handleChange.bind(this);
      this.state = {
        phraseIndex: 0,
        writing: true, //if writing === false then we are deleting
          labels: []
      };
  }

  handleChange() {
      if(this.state.writing) {
          this.setState({ writing: false});
      }
      else {
          this.setState({
              phraseIndex: (this.state.phraseIndex + 1) % commentsData.length,
              writing: true,
          });
      }
  }

  render() {
    return (
        <div className="position-relative">
        <section className="section section-hero section-shaped">
          {/*<div className="shape shape-style-1 bg-gradient-info">*/}
          {/*</div>*/}
          <Container className="shape-container d-flex align-items-center pt-2"
          style={{marginTop: "200px", marginBottom: "250px"}}>
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                <Col lg="5" md="5" className={"pr-3"}>
                  <SelfWritingTextSolidBackgroundNoToxicity handleChange={this.handleChange} commentsData={commentsData} />
                  </Col>
                    <Col lg="5" md="5">
                    <LabelsDisplayerSolidBackgroundNoToxicity
                        labels={this.state.writing ? [] : commentsData[this.state.phraseIndex].labels}
                    />
                    </Col>
                </Row>
              </div>
          </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
        </section>
        </div>
    );
  }
}

export default withTranslation("home")(LandingSolidBackgroundNoToxicity);
