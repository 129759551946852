import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import PrivacyContent from "../components/Privacy/PrivacyContent.component"
import NavbarBackground from "../components/Navbars/NavbarBackground.component";


class Privacy extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <NavbarBackground />
            <main ref="main">
              <PrivacyContent />
            </main>
          <SimpleFooter />
          </Suspense>
      </>
    );
  }
}

export default Privacy;
