import React from "react";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import CommentsList from "./CommentsList.component";
import LiveTaggingDemo from "./LiveTaggingDemo.component";
import DemoExplanationModal from "./DemoExplanationModal.component";
import jsonData from "../../assets/json/allCombined.json";

class DemoContent extends React.Component {

   constructor() {
    super();
    this.state = {
        fetched: false,
        headline: "",
        snippet: "",
        comments: [],
        bigText: false
    };
  }

  componentDidMount() {
        this.setState({
            fetched: true,
            headline: jsonData.headline,
            snippet: jsonData.snippet,
            comments: jsonData.comments//.slice(0, 1)
        });
      // console.log(Date.now());
      //   this.callApi()
      //       .then(res => {
      //           console.log(Date.now());
      //           this.setState({
      //               fetched: true,
      //               headline: res.headline,
      //               snippet: res.snippet,
      //               comments: res.comments//.slice(0, 1)
      //           });
      //       })
      //       .catch(err => console.log(err));
    }

    callApi = async () => {
        const response = await fetch(`/article?orderedID=0`);
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);

        return body;
    };

  render() {
      if (this.state.fetched === false) {
          return null;
      }
      const { t } = this.props;

      return (
          <>
            <Container className="shape-container align-items-center">
                <Container className="mt-3">
                <Row>
                    <Col lg="9">
                        <Row><h1> {this.state.headline}</h1></Row>
                        <Row><h5> {this.state.snippet}</h5></Row>
                    </Col>
                    <Col lg="1">
                    </Col>
                    <Col lg="2">
                        <Button block className="btn-icon active"
                                color="secondary" 
                                type="button"
                                onClick={(event) => {event.preventDefault();
                                    this.setState({explanationModalOpened: true})
                                }}>
                        {t("demoContent.what_am_i_seeing")}
                        </Button>
                        <div className={"align-items-center"}>
                        <Button block className="btn-icon active mt-2 p-0"
                                size={"sm"}
                                color="secondary"
                                type="button"
                                onClick={(event) =>
                                    this.setState({bigText: !this.state.bigText})
                                }>
                            {this.state.bigText ? t("demoContent.smaller_text") : t("demoContent.bigger_text")}
                        </Button>
                        </div>
                    </Col>
                </Row>
                </Container>
                <DemoExplanationModal isOpen={this.state.explanationModalOpened} handleClose={() => this.setState({explanationModalOpened: false})}/>
                {/*<div className="mt-3"/>*/}
                {/*    <LiveTaggingDemo bigText={this.state.bigText}/>*/}
                <div className="mt-4"/>
                <CommentsList comments={this.state.comments} bigText={this.state.bigText}/>
            </Container>
        </>
    );
  }
}

export default withTranslation("demo")(DemoContent);
