import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import LandingSolidBackgroundNoToxicity
  from "../components/AnimationSolidBackgroundNoToxicity/LandingSolidBackgroundNoToxicity.component";

class AnimationSolidBackgroundNoToxicity extends React.Component {

  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <div style={{backgroundColor: "#157bcf"}}>
          <CustomNavbar />
            <main ref="main">
              <LandingSolidBackgroundNoToxicity/>
            </main>
          <SimpleFooter />
          </div>
        </Suspense>
      </>
    );
  }
}

export default AnimationSolidBackgroundNoToxicity;
