import React from "react";

import { withTranslation } from 'react-i18next';

// reactstrap components
import {
    Container,
    Row,
    Card, CardTitle, ListGroup, ListGroupItem, CardDeck,
} from "reactstrap";



class PersListItem extends React.Component {
    render() {
        return <ListGroupItem
            style={{borderBottom: "0"}}
            className="list-group-item
                    lead mt-0 mb-0 py-0">
            <span className="btn-inner--icon mr-2">
                <i className="ni ni-check-bold"/>
            </span>
            {this.props.children}
        </ListGroupItem>
    }
}

class CategoryListItem extends React.Component {
    render() {
        return <ListGroupItem
            style={{borderBottom: "0"}}
            className="list-group-item
                    lead mt-0 mb-0 py-0">
            <span className="btn-inner--icon mr-2">
                <i className="ni ni-bold-right"/>
            </span>
            {this.props.children}
        </ListGroupItem>
    }
}

class UseCase extends React.Component {
  render() {
    const { t } = this.props;
    return (
    <>
        <Container 
        className={"align-content-center p-5 mt-5 mb-5 rounded " 
        + this.props.bgColor}>
            <Row>
                <h1 className="text-white">
                <span>
                    <i className={"mr-4 " + this.props.icon}/>
                </span>{this.props.title}</h1>
            </Row>
            <CardDeck style={{flex:1}} className="mt-3">
                <Card className="card-lift--hover shadow border-0 pb-5">
                    <CardTitle  className="pt-2">
                        <h2 className="text-center">
                            {t(["useCaseComponent.categories", "Categories"])}
                        </h2>
                    </CardTitle>
                    <ListGroup  className="list-group list-group-flush">
                        {this.props.categoryList.map(
                            (categoryElement, index) => {
                                return <CategoryListItem 
                                    key={this.props.title+"_category_"+index}> 
                                    {categoryElement}
                                </CategoryListItem>
                            }
                        )}
                    </ListGroup>
                </Card>
                {this.props.readerPros ? 
                    <Card className="card-lift--hover shadow border-0">
                        <CardTitle  className="pt-2">
                            <h2 className="text-center">
                            {t(["useCaseComponent.reader", "For the reader"])}
                                </h2>
                        </CardTitle>
                        <ListGroup  className="list-group list-group-flush">
                            {this.props.readerPros.map(
                                (readerPro, index) => {
                                    return <PersListItem 
                                    key={this.props.title+"_reader_"+index}> {readerPro} 
                                    </PersListItem>
                                }
                            )}
                        </ListGroup>
                    </Card>
                : ""} 
                <Card className="card-lift--hover shadow border-0 pb-6">
                    <CardTitle  className="pt-2">
                        <h2 className="text-center">
                            {t(["useCaseComponent.editor", "For the editor"])}
                        </h2>
                    </CardTitle>
                    <ListGroup  className="list-group list-group-flush">
                        {this.props.editorPros.map(
                                (editorPro, index) => {
                                    return <PersListItem 
                                    key={this.props.title+"_editor_"+index}> {editorPro}
                                    </PersListItem>
                                }
                            )}
                    </ListGroup>
                </Card>
            </CardDeck>
        </Container>
      </>
    );
  }
}

UseCase.defaultProps={
    bgColor: "bg-gradient-default",
    icon: "fa fa-newspaper-o",
    title: "For newspapers",
    categoryList: ["Toxicity", "Sentiment", "Stance", "Style", "Topic", "Political view"],
    readerPros: [],
    editorPros: ["Automatic filter out toxic comments", "Valuable audience analytics", 
    "Increased reader engagement", "Improved reputation", "More advertising opportunities"]
}

export default withTranslation("home")(UseCase);
