import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import NavbarBackground from "../components/Navbars/NavbarBackground.component";
import SimpleFooter from "../components/Footers/SimpleFooter.component";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import DemoContent from "../components/Demo/DemoContent.component.js";
import AlertDismissableDemo from "../components/Demo/AlertDismissableDemo.component.js";


class Demo extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  constructor() {
    super();
    this.state = {
      alertDismissableDemoIsOpen: true
    };
  }


  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <NavbarBackground />
              <main ref="main">
                  {/*<AlertDismissableDemo isOpen={this.state.alertDismissableDemoIsOpen} handleClose={() => this.setState({alertDismissableDemoIsOpen: false})}/>*/}
                  <DemoContent />
              </main>
          <SimpleFooter />
        </Suspense>
      </>
    );
  }
}

export default Demo;
