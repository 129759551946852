import React from "react";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
    Container,
    Row,
    Card, CardBody, ListGroup, ListGroupItem, CardDeck,
} from "reactstrap";


class AdvantageLI extends React.Component {
    render() {
        return (<ListGroupItem
            style={{borderBottom: "0"}}
            className="list-group-item lead mb-0 pb-0 mt-0 pt-2">
                <span className="btn-inner--icon mr-2">
                    <i className={`fa fa-${this.props.icon}`}/>
                </span>
                {this.props.textComponent}
        </ListGroupItem>
        );
    }

}

class Advantages extends React.Component {
  state = {
    advantages_var:[
        {
            icon: "language",
            text: "Support for English, German, Italian, Spanish and French",
            key: "support"
        },
        {
            icon: "line-chart",
            text: "Human comparable performance",
            key: "performance"
        },
        {
            icon: "tags",
            text: "Minimal tagging effort: only 4 man hours for labelling needed",
            key: "tags_few"
        },
        {
            icon: "wrench",
            text: "Minimal integration effort: query the REST api to label new comments",
            key: "rest_api"
        },
        {
            icon: "toggle-on",
            text: "Choose your own categories or leverage the power of ones proposed",
            key: "custom_categories"
        }
    ]
  }
  render() {
    const { t }= this.props;
    return (
    <>
      <section className="section section-lg bg-primary pt-5">
        <div className="text-center">
            <h1 className="text-white">{t("advantages.technical_title")}</h1>
        </div>
          <Container className="mt-4 pb-2">
              <Row>
              <CardDeck style={{flex:1}} className="p-2">
                  <Card className="card-lift--hover shadow ">
                      <CardBody className="mt--1">
                      <ListGroup  className="list-group list-group-flush">
                          {this.state.advantages_var.map((advantage, index) => {
                            return (
                            <AdvantageLI icon={advantage.icon} 
                                key={"advatange_li_"+advantage.icon+"_"+index}
                                textComponent={t([`advantages.${advantage.key}`, advantage.text])}>
                            </AdvantageLI>
                            )
                          })}
                      </ListGroup>
                      </CardBody>
                  </Card>
              </CardDeck>
              </Row>
          </Container>
 <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
          <polygon
            className="fill-secondary"
            points="2560 0 2560 100 0 100"
          />
        </svg>
        </div>
      </section>
      </>
    );
  }
}

export default withTranslation("home")(Advantages);
