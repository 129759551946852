import React, { useState } from 'react';
import { useTranslation} from 'react-i18next';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const DropdownLanguage = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { i18n } = useTranslation(); 
  const toggle = () => setDropdownOpen(prevState => !prevState);
  let dropdownToggle = i18n.language;
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    dropdownToggle = lng
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" inNavbar="true">
      <DropdownToggle caret
      className="btn-neutral btn-icon btn btn-default">
        {dropdownToggle.toUpperCase()}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changeLanguage('en')}>EN</DropdownItem>
        <DropdownItem onClick={() => changeLanguage('it')}>IT</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropdownLanguage;