import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
// change 1/2 to see different meet setups
import WhoWeAreContent from "../components/WhoWeAre/WhoWeAreContent.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

class WhoWeAre extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <main ref="main">
              <WhoWeAreContent />
            </main>
          <SimpleFooter />
        </Suspense>
      </>
    );
  }
}

export default WhoWeAre;
