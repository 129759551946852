import React from "react";

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'

// nodejs library that concatenates classes
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, UncontrolledAlert
} from "reactstrap";


class ContactContent extends React.Component {

    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            message: "",
            nameError: false,
            emailError: false,
            messageError: false,
            errorMessage: "",
            success: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        this.setState({
            success: false,
            [event.target.name]: event.target.value,
            [event.target.name + "Error"]: false
        });
    }

    validateEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log('Current values ' + this.state.name + this.state.email + this.state.message);
        let errorMessage = "";
        if(this.state.name.length === 0) {
            errorMessage += "Invalid name. ";
            this.setState({
                nameError: true});
        }
        if(!this.validateEmail(this.state.email)) {
            errorMessage += "Invalid email. ";
            this.setState({
                emailError: true});
        }
        if(this.state.message.length === 0) {
            errorMessage += "Invalid message. ";
            this.setState({
                messageError: true});
        }
        this.setState({
            errorMessage: errorMessage});

        if(!errorMessage) {
            this.postEmail()
                .then(res => {
                    console.log(res);
                    this.setState({
                        success: true
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        errorMessage: err.toString(),
                    })
                });
        }

        window.scrollTo(250, 250)
    }

    postEmail = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                { "name": this.state.name,
                    "email": this.state.email,
                "message": this.state.message})
        };
        return fetch('/contactmail', requestOptions)
            .then(response => response.json())
    };

    render() {
        const { t } = this.props;

        return (
            <>
            <section className="section section-lg section-contact-us pt-5 pb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="8">
                            {!(this.state.errorMessage) ? null :
                                <UncontrolledAlert color="danger" fade={true}>
                                        <span className="alert-inner--icon">
                                            <i className="ni ni-support-16" />
                                        </span>
                                        <span className="alert-inner--text ml-1">
                                            <strong>
                                                {t("contactContent.error")}
                                            </strong> {this.state.errorMessage}
                                        </span>
                                </UncontrolledAlert>}
                            {!this.state.success ? null :
                                <UncontrolledAlert color="success" fade={true}>
                                        <span className="alert-inner--icon">
                                        <i className="ni ni-like-2" />
                                        </span>
                                        <span className="alert-inner--text ml-1">
                                        <strong>
                                            {t("contactContent.success")} 
                                        </strong>
                                            {t("contactContent.success_message")} {this.state.email}.
                                        </span>
                                </UncontrolledAlert>}
                            <Card className="bg-gradient-secondary shadow">
                                <CardBody className="p-lg-5">
                            <h4 className="mb-1"> {t("contactContent.card_title")}</h4>
                                    <p className="mt-0">
                                    {t("contactContent.card_header")} 
                                        <a href="/who-we-are"> LinkedIn.</a>
                                    </p>
                                    <FormGroup
                                        className={classnames("mt-2", {
                                            focused: this.state.nameFocused
                                        })}
                                    >
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-user-run" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="name"
                                                placeholder={t("contactContent.card_inputname_placeholder")}
                                                type="text"
                                                onFocus={e => this.setState({ nameFocused: true })}
                                                onBlur={e => this.setState({ nameFocused: false })}
                                                onChange={this.handleInputChange}
                                                className="is-invalid"
                                                invalid
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup
                                        className={classnames({
                                            focused: this.state.emailFocused
                                        })}
                                    >
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="email"
                                                placeholder={t("contactContent.card_inputmail_placeholder")}
                                                type="email"
                                                onFocus={e => this.setState({ emailFocused: true })}
                                                onBlur={e => this.setState({ emailFocused: false })}
                                                onChange={this.handleInputChange}
                                                className={this.state.emailError ? "is-invalid" : null}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            cols="80"
                                            name="message"
                                            placeholder={t("contactContent.card_inputtext_placeholder")}
                                            rows="4"
                                            type="textarea"
                                            onChange={this.handleInputChange}
                                            className={this.state.messageError ? "is-invalid" : null}
                                        />
                                    </FormGroup>
                                    <p className="mt-1 mb-4">
                                        <Trans i18nKey="contactContent.data_treatment" ns="contact">
                                        By submitting this form, You consent to data being stored according to the guidelines set out in the <a href="/privacy"> privacy policy</a>.
                                        </Trans>
                                    </p>
                                    <Button
                                            block
                                            className="btn-round"
                                            color="default"
                                            size="lg"
                                            type="button"
                                            onClick={this.handleSubmit}
                                        >
                                            {t("contactContent.card_send_message")}
                                    </Button>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            </>
        );
    }
}

export default withTranslation("contact")(ContactContent);