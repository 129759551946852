import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import PipelineSolution from "../components/Home/PipelineSolution.component.js";
import Landing from "../components/Home/Landing.component";
import UseCases from "../components/Home/UseCases.component";
import Advantages from "../components/Home/Advantages.component";
import {makeWarmupDemoRequest} from "../components/Demo/LiveTaggingDemo.component";

class Home extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    // commented out by Luca Campanella on 07/11/2021 to save money
    // makeWarmupDemoRequest();
  }

  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <main ref="main">
              <Landing />
              <PipelineSolution />
              <Advantages/>
              <UseCases />
            </main>
          <SimpleFooter />
        </Suspense>
      </>
    );
  }
}

export default Home;
