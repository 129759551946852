import React, {Component} from 'react';
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css';
import { withTranslation } from 'react-i18next';

import {
    Card,
    CardBody,
  } from "reactstrap";


class SelfWritingTextSolidBackgroundNoToxicity extends Component {
  constructor() {
      super();
      //this.setInitialState = this.setInitialState.bind(this);
      this.state = {typistIndex: 0, 
                    reload: false};
  }

  render() {
    const { t, i18n }= this.props;
    i18n.on('languageChanged', (lng) => {
      this.setState({reload: true});
    });

    return (
      <Card className="shadow border-0" style={{height: '260px'}}>
        <CardBody className="m-3" style={{fontSize: 24, height: '200px'}}>
          <Typist
              key={this.state.typistIndex}
              onLineTyped={this.props.handleChange}
              onTypingDone={() => this.setState({ typistIndex: this.state.typistIndex + 1 })}
          >
          {this.props.commentsData.map(comment => ([
              <span >{t([`comments.${comment.id}`, comment.text])}</span>,
              <Typist.Backspace count={t([`comments.${comment.id}`, comment.text]).length} 
                delay={3000} />,
          ]))}
          </Typist>
          </CardBody>
        </Card>
    );
  }
}

export default withTranslation("home")(SelfWritingTextSolidBackgroundNoToxicity);
