import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";


// Multilanguage
import './i18n.js';

// Redirect 
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";


import Home from "./views/Home.js";
import Demo from "./views/Demo.js";
import Contact from "./views/Contact.js";
import WhoWeAre from "./views/WhoWeAre.js";
import Technology from "./views/Technology.js";
import Privacy from "./views/Privacy.js"
import CookiesPage from "./views/Cookies.js";
import CookiesModal from "./components/Cookies/CookiesModal.component.js"
import FallBackComponent from "./components/Fallback/FallBack.component.js"
import NoMatch from "./views/NoMatch.js";
import AnimationSolidBackgroundNoToxicity from "./views/AnimationSolidBackgroundNoToxicity";
import Why from "./views/Why";

ReactDOM.render(
  <>
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route path="/demo" exact render={props => <Demo {...props} />} />
      <Route path="/who-we-are" exact render={props => <WhoWeAre {...props} />} />
      <Route path="/contact" exact render={props => <Contact {...props} />} />
      <Route path="/portal" exact render={props => <Home {...props} />} />
      <Route path="/technology" exact render={props => <Technology {...props} />} />
      <Route path="/cookies" exact render={props => <CookiesPage {...props} />} />
      <Route path="/privacy" exact render={props => <Privacy {...props} />} />

      <Route path="/animation-solid-background-no-toxicity" exact render={props => <AnimationSolidBackgroundNoToxicity {...props} />} />
      <Route path="/why" exact render={props => <Why {...props} />} />
      <Route component={NoMatch}/>
    </Switch>
  </BrowserRouter>
  <Suspense fallback={FallBackComponent}>
      <CookiesModal />
    </Suspense>
  </>,
  document.getElementById("root")
);
