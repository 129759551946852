import React, { Component, Suspense } from "react";
import { withTranslation } from 'react-i18next';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Trans } from 'react-i18next'

class CookiesModal extends Component{
    render(){
        const { t } = this.props;

        return(
            <CookieConsent
                buttonText={t(["cookiesModal.buttonText", "I agree"])}
                style={{ backgroundColor: "#172b4d !important" }}
                buttonStyle={{  color: "#227ee0", 
                                backgroundColor:"#fff",
                                textTransform: "uppercase",
                                willChange: "transform",
                                cursor: "pointer",
                                letterSpacing: "0.025rem",
                                boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)",
                                padding: "0.625rem 1.25rem",
                                borderRadius: "0.25rem",
                                fontWeight: "600",
                                fontSize: "0.875rem" }}
                buttonClasses="btn btn-neutral"
            >
                <Trans i18nKey="cookiesModal.text" ns="cookies">
                This website uses <a href="/cookies">cookies</a> to enhance the user experience.
                </Trans>
            </CookieConsent>
        )
    }
}

export default withTranslation("cookies")(CookiesModal);
