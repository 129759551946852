import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";

export default class NoMatchContent extends Component{
    render(){
        return(
            <>
            <section className="section p-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("../../assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div className="pl-4">
                      <h4 className="display-3">Page not found.</h4>
                      <p>
                      Our portal is currently being developed. <br/>If you want to know better what we do, check out the 
                        <a href="/demo"> demo</a>. If you liked it, <a href="/contact"> contact us! </a>
                      </p>
                    </div>
                  </div>

                </Col>
              </Row>
            </Container>
            </section>
            </>
        )
    }
}
/* 
<h1> Our portal is currently being developed. If you want to know better what we do, check out the 
<a href="/demo">demo</a>. If you like it, <a href="/contact"> contact us! </a></h1> */