import React, { Component } from "react";

export default class NoMatchContent extends Component{
    render(){
        return(
            <>
            <h1> Loading </h1>
            </>
        )
    }
}
