import React, {Component} from 'react';

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'

import {
    Button,
    Container,
    Row,
    Col,
  } from "reactstrap";
import SelfWritingLandingText from ".//SelfWritingText.component";
import LabelsLandingDisplayer from ".//LabelsDisplayer.component";

const commentsData = [
    {
        "id": "0",
        "text": "I hate you, you idiot.",
        "labels": ["toxic", "negative"],
    },
    {
        "id": "5",
        "text": "An outstanding article, thanks for your insight.",
        "labels": ["approved", "positive", "for"],
    },
    {
        "id": "10",
        "text" : "You are wrong. Our economy is doing worse than ever.",
        "labels": ["negative", "against", "economics_and_finance"],
    },
    {
        "id": "15",
        "text" : "And Mr. Wu just figured this out?",
        "labels": ["sarcastic"],
    },
    {
        "id": "20",
        "text": "This proves how Republicans have bad intentions.",
        "labels": ["negative", "for", "liberal"],
    },
    {
        "id": "25",
        "text": "Any type of comment",
        "labels": ["custom"],
    }
];

class Landing extends Component {
  constructor(){
      super();
      this.handleChange = this.handleChange.bind(this);
      this.state = {
        phraseIndex: 0,
        writing: true, //if writing === false then we are deleting
          labels: []
      };
  }

  handleChange() {
      if(this.state.writing) {
          this.setState({ writing: false});
      }
      else {
          this.setState({
              phraseIndex: (this.state.phraseIndex + 1) % commentsData.length,
              writing: true,
          });
      }
  }

  render() {
    const { t }= this.props;
    return (
        <div className="position-relative">

        <section className="section section-hero section-shaped">
          <div className="shape shape-style-1 bg-gradient-info">
          </div>
          <Container className="mt-5">
            <div className="col px-0">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center" lg="10">
                  {/*/!*Fostering healthy discourse with artificial intelligence for online media providers*!/*/}
                    <h1 style={{marginTop: "-1.2rem", lineHeight: "1.15"}} className="text-white">
                          {t(["landing.mission", 
                          "Empower the comment section of your website with artificial intelligence"])}
                    </h1>
                    <h5 style={{marginTop: "1rem", marginBottom: "1.8rem"}} className="text-white">
                      <Trans i18nKey="landing.subtitle" ns="home">
                        Automatically label each comment with the categories <i>you</i> choose. 
                        Use the category labels for moderation and for the UI.
                      </Trans>
                    </h5>
                </Col>
              </Row>
            </div>
          </Container>
          <Container className="shape-container d-flex align-items-center pt-2">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                <Col lg="5" md="5" className={"pr-3"}>
                  <SelfWritingLandingText handleChange={this.handleChange} commentsData={commentsData} />
                  </Col>
                    <Col lg="5" md="5">
                    <LabelsLandingDisplayer
                        labels={this.state.writing ? [] : commentsData[this.state.phraseIndex].labels}
                    />
                    </Col>
                </Row>
              </div>
          </Container>


            <Container>
            <Row className="align-items-center justify-content-center">
              <div className="btn-wrapper mt-5">
                <Button
                  className="btn-white btn-icon mb-3 mb-sm-0"
                  color="default"
                  href="/demo"
                  size="lg"
                >
                  <span className="btn-inner--text">
                    {t(["landing.try_demo", "Try the demo"])}
                  </span>
                </Button>{" "}
                <Button
                  className="btn-icon mb-3 mb-sm-0"
                  color="github"
                  href="/contact"
                  size="lg"
                >
                  <span className="btn-inner--text">
                  {t(["landing.contact_us", "Contact us"])}
                  </span>
                </Button>
              </div>
            </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
        </section>
        </div>
    );
  }
}

export default withTranslation("home")(Landing);
