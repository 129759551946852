import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import NavbarBackground from "../components/Navbars/NavbarBackground.component.js"
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import ContactContent from "../components/Contact/ContactContent.component.js";


class Contact extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
          <main ref="main">
              <NavbarBackground />
              <ContactContent />
          </main>
          <SimpleFooter />
        </Suspense>
      </>
    );
  }
}

export default Contact;
