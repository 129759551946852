import React, { Component } from "react";
import {Container, Row, Col} from "reactstrap";
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next';

class PrivacyContent extends Component{
    render(){
        const { t } = this.props;
        return(
            <>
            <section className="section">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-1">
                  <div className="d-flex px-3">
                    <div className="pl-4">
                      <h2 className="display-2">{t(["policyContent.title","Privacy policy"])}</h2>
                        <p>
                            {
                            t(["policyContent.header","This privacy policy notice is served by Artivon, under the website Artivon.com. The purpose of this policy is to explain to you how we control, process, handle and protect your personal information through the business and while you browse or use this website.  If you do not agree to the following policy you may wish to cease viewing / using this website, and or refrain from submitting your personal data to us."])}
                        </p>
                        <h3> {t(["policyContent.par.1.title","Policy key definitions"])} </h3>
                        <p>
                            {t(["policyContent.par.1.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.2.title","Key principles of GDPR"])} </h3>
                        <p>
                            {t(["policyContent.par.2.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.3.title","Processing of your personal data"])} </h3>
                        <p>
                            {t(["policyContent.par.3.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.4.title","ICO Data Protection Register"])} </h3>
                        <p>
                            {t(["policyContent.par.4.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.5.title","Lawful basis: consent"])} </h3>
                        <p>
                            {t(["policyContent.par.5.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.6.title","Data security and protection"])} </h3>
                        <p>
                            {t(["policyContent.par.6.content","Content not retrieved"])} 
                        </p>
                        <h3> {t(["policyContent.par.7.title","Fair and Transparent Privacy Explained"])} </h3>
                        <p>
                            {t(["policyContent.par.7.content","Content not retrieved"])} 
                        </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            </section>
            </>
        )
    }
}

export default withTranslation("privacy")(PrivacyContent)