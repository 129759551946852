import React from "react";
import { withTranslation } from 'react-i18next';

import UseCase from "./UseCase.component.js";


class UseCases extends React.Component {
  state = {
    use_cases:[
      {
        "id": "uc_newspaper",
        "bgColor": "bg-gradient-default",
        "icon": "fa-newspaper-o",
        "title": "For newspapers",
        "categories": ["Toxicity", "Sentiment", "Stance", "Style", "Topic", "Political view"],
        "readerPros": ["Filter out useless content", 
                        "Overview of public opinion", 
                        "Get out of mainstream view"],
        "editorPros": ["Automatically filter out toxic comments", 
                        "Valuable audience analytics", 
                        "Increased reader engagement", 
                        "Improved reputation", 
                        "More advertising opportunities"]
      },
      {
        "id": "uc_cooking",
        "bgColor": "bg-gradient-success",
        "icon": "fa-birthday-cake",
        "title": "Cooking websites",
        "categories": ["Toxicity", 
                        "Comment needs reply or not", 
                        "Sentiment", 
                        "Main message extraction: switch of ingredient, recipe praise or critique, recipe variant"],
        "readerPros": ["Read comments addressing his or her inquiry", 
                        "More easily browse ideas or common difficulties"],
        "editorPros": ["Easier moderation", 
                        "No duplicated questions", 
                        "Increased reader engagement", 
                        "Analytics on recipe reception", 
                        "Common mistakes summary"]
      },
      {
        "id": "uc_bloggers",
        "bgColor": "bg-gradient-info",
        "icon": "fa-rss",
        "title": "Bloggers and influencers",
        "categories": ["Toxicity", "Sentiment", "Stance", "Style", "Topic", "Political view"],
        "readerPros": [],
        "editorPros": ["Easier moderation", 
                      "Better analytics"]
      }
    ]
  }
  render() {
    const { t } = this.props;
    return (
    <>
      <section className="section section-lg bg-secondary">
        <div className="text-center">
            <h1 style={{marginTop: '-80px'}}>
              {t(["useCases.title", "Use case examples"])}
            </h1>
        </div>

        {this.state.use_cases.map((use_case, index) => {
          return (
          <UseCase 
          bgColor= {use_case.bgColor}
          icon={`fa ${use_case.icon}`}
          title= {t([`useCases.${use_case.id}.title`, use_case.title])}
          categoryList={t([`useCases.${use_case.id}.categories`, 
                          use_case.categories],
                          {returnObjects: true, joinArrays: false})}
          readerPros={t([`useCases.${use_case.id}.readerPros`, 
                          use_case.readerPros],
                          {returnObjects: true, joinArrays: false})}
          editorPros={t([`useCases.${use_case.id}.editorPros`, 
                          use_case.editorPros],
                          {returnObjects: true, joinArrays: false})}
          />)
        })}
        
      </section>
      </>
    );
  }
}

export default withTranslation("home")(UseCases);
