import React from "react";

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
// reactstrap components
import {
  Col,
  Container
} from "reactstrap";

class CustomVerticalElement extends React.Component {
    render() {
        return (<VerticalTimelineElement
                className="vertical-timeline-element mt-n4 mb-5"
                contentStyle={{boxShadow: "3px 3px 30px #ddd", ...this.props.contentStyle}}
                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)', marginRight: "0.2px"}}
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                icon={<i className={`fa fa-${this.props.icon} icon-timeline-centered`} />}
            >
                <h3 className={`vertical-timeline-element-title ${this.props.textClassName}`}>{this.props.title}</h3>
                <h5 className={this.props.textClassName}>
                    {this.props.children}
                </h5>
            </VerticalTimelineElement>
        );
    }
}

class CustomVerticalElementWhite extends React.Component {
    render() {
        return (<CustomVerticalElement {...this.props}/>
        );
    }
}

class CustomVerticalElementBlue extends React.Component {
    render() {
        return (
            <CustomVerticalElement {...this.props}
        contentStyle={{ background:"linear-gradient(150deg, #2ea8ff 30%, #4396df 70%, #4383df 95%)",
            color: '#fff'}} textClassName="text-white"/>
        );
    }
}


class Problem extends React.Component {
  state = {
      stepSolution:[
        {
            "title": "Load your comments",
            "icon": "upload",
            "text": "Our pre-trained model adapts to your own data for maximum accuracy."
        },
        {
            "title": "Choose the labels",
            "icon": "search",
            "text": "Use predefined categories and labels or choose your own. The technology adapts to your needs."
        },
        {
            "title": "Train",
            "icon": "gear",
            "text": "Let our AutoML do the work, training and adapting itself to your data and your categories."
        },
        {
            "title": "Deploy",
            "icon": "star",
            "text": "Automatically tag any new comment by querying the REST API we deploy for you. Alternatively, download the model to run it on premise."
        },
        {
            "title": "Employ and enjoy",
            "icon": "magic",
            "text": "Use the information to ease the moderation process or to drive engagement directly in the user interface of your website. Happier moderators and happier readers. Gain precious insights on opinions and topics of dicussion of the readers of your website."
        }]
    }
  render() {
    const { t } = this.props;
    return (
    <>
        <section className="section section-lg pt-5">
        <Container>
        <div className="text-center mt-n4">
            <h1> {t(["howItWorks.title","How it works"])} </h1>
        </div>
            <div>
                <h5>
                    <Trans i18nKey="howItWorks.subtitle" ns="home">
                        Our technology tags any comment along different desired categories. 
                        Each category is comprised of two or more possible labels. 
                        A typical example is the category <i>sentiment</i>, for
                        which the labels are <i>positive, negative, neutral</i>.
                    </Trans>
                    <br/>
                    {t(["howItWorks.subsubtitle", "Here's how it works:"])}
                </h5>
            </div>
        <Col lg="12">
        <VerticalTimeline layout={"1-column"} className="vertical-timeline-custom-line mt-4 mb-n8">
            {this.state.stepSolution.map((element, index)=>{
                if((index % 2)===0){
                    return(
                    <CustomVerticalElementBlue 
                        title={t([`howItWorks.pipeline.${element.icon}.title`,element.title])} 
                        icon={element.icon}>
                        {t([`howItWorks.pipeline.${element.icon}.text`,element.text])}
                    </CustomVerticalElementBlue>)
                }
                else{
                    return(
                    <CustomVerticalElementWhite
                        title={t([`howItWorks.pipeline.${element.icon}.title`,element.title])} 
                        icon={element.icon}>
                        {t([`howItWorks.pipeline.${element.icon}.text`,element.text])}
                    </CustomVerticalElementWhite>)
                }
            })}
            </VerticalTimeline>
            </Col>
            </Container>
        </section>
    </>
    );
  }
}

export default withTranslation("home")(Problem);


