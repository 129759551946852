import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
// change 1/2 to see different meet setups
import FallBackComponent from "../components/Fallback/FallBack.component"
import WhyDescription from "../components/Why/WhyContent.component";

class Why extends React.Component {
  render() {
    return (
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <main ref="main">
              <WhyDescription />
            </main>
          <SimpleFooter />
        </Suspense>
    );
  }
}

export default Why;
