import React from "react";

// reactstrap components
import {Button, Input, Alert} from "reactstrap";
import Comment from "./Comment.component";
import {BeatLoader} from "react-spinners"
import { withTranslation } from "react-i18next";
import axios from "axios";

function argMax(array) {
    return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

export const makeWarmupDemoRequest = () => {
    //request to warmup gcloud model
    axios.post('/tagcomment', { "comments": ["Placeholder comment"],
            "title": "This request serves only to warmup google cloud model and reduce response time."},
        {timeout: 14000})
        .then(() => console.log("Warmup request successful"))
        .catch(err => {console.log("warmup request failed"); console.log(err);});
};

class LiveTaggingDemo extends React.Component {

    static defaultProps = {
        bigText: false
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            currComment: null,
            loading: false,
            error: false,
            textInputClass: "",
            // className="is-valid"
            tasks: [
                {
                    name: "sentiment",
                    displayName: "Sentiment",
                    labels: ["negative", "positive", "neutral"],
                },
                {
                    name: "stance",
                    displayName: "Stance",
                    labels: ["for", "against", "discuss"],
                },
                {
                    name: "topic",
                    displayName: "Topic",
                    labels: ["foreign_policy",
                        "economics_and_finance",
                        "law_and_order", "migration",
                        "environment",
                        "social_issues",
                        "parties_and_politics"],
                },
                {
                    name: "style",
                    displayName: "Style",
                    labels: ["subjective", "factual", "personal", "sarcastic"],
                },
                {
                    name: "poliview",
                    displayName: "Political view",
                    labels: ["liberal", "conservative", "neither"],
                }
            ]
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // commented out by Luca Campanella on 07/11/2021 to save money
        // makeWarmupDemoRequest();
    }

    handleChange(event) {
        this.setState({value: event.target.value,
            textInputClass: ""});
    }

    handleSubmit(event) {
        event.preventDefault();

        const commentText = this.state.value;

        if(commentText === "") {
            this.setState({textInputClass: "is-invalid"});
            return;
        }
        this.setState({ currComment: null,
            loading: true,
            textInputClass: "is-valid"});

        axios.post('/tagcomment', { "comments": [commentText],
            "title": "Donald Trump has waged almost continual warfare against his critics. The result: " +
                "more people are paying attention to the president than ever."},
            {timeout: 14000})
            .then(response => response.data)
            .then((data) => {
                console.log("Reply received succesfully");
                console.log(JSON.stringify(data));
                    const newComment = {commentBody: commentText};
                    this.state.tasks.forEach((task, index) => {
                        let maxium_entry = argMax(data[task.name][0].probabilities);
                        newComment[task.name + "_label"] = task.labels[maxium_entry];
                    });
                    this.setState({ currComment: newComment,
                        loading: false,
                    error: false});
                }
            )
            .catch((error) => {
                console.log(error);
                this.setState({ currComment: null,
                    loading: false,
                    error: true});
            });
    }


    render() {
        const { t }= this.props;
        return (
            <>
                <span className="text-primary bt--20">
                    {t("liveTagging.try_me")}:
                    <i className="ni ni-bold-down"/> </span>

                <form onSubmit={this.handleSubmit}>
                    <Input placeholder={t("liveTagging.insert_comment")} type="text"
                           className={this.state.textInputClass + " text-dark mt-2"}
                           value={this.state.value} onChange={this.handleChange} />
                    <Button className="mt-2" color="primary" type="submit" value="Submit">
                        {t("liveTagging.evaluate")}
                    </Button>
                </form>

                    {this.state.currComment ?
                        <div className="mt-423">
                            <Comment
                                comment={this.state.currComment}
                                bigText={this.props.bigText}
                                taskNames={this.state.tasks.map((task) => task.name)}/>
                        </div>
                        :
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {this.state.error ?
                                <Alert color="danger" className={"mt-4"}>
                                    The request is taking too long due to Google cloud request delivery policies,
                                    please reload and try again.
                                </Alert>
                                :
                                <BeatLoader
                                    size={40}
                                    color={"#5e72e4"}
                                    loading={this.state.loading}
                                />
                            }
                        </div>}
                </>
        );
    }
}

export default withTranslation("demo")(LiveTaggingDemo);

