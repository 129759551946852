import React from "react";
import {Badge} from "reactstrap";

class LabelBadge extends React.PureComponent {

    labelToEmoji = {
        "pos": "😊", "neu": "😶", "neg": "😞",
        "positive": "😊", "neutral": "😶", "negative": "😞",
        "for": "👍", "against": "👎", "observing": "👀",
        "agree": "👍", "disagree": "👎", "discuss": "👐", "unrelated": "⭕",
        "foreign_policy": "🚩", "economics_and_finance": "💵", "law_and_order": "⚖️", "migration": "👣",
        "environment": "🌍", "social_issues": "🗣", "parties_and_politics": "🎤",
        "subjective": "👊", "factual": "🔩", "personal": "☝️", "sarcastic": "🙄",
        "liberal": "⬅️", "conservative": "➡️", "neither": "⏺"
    };

    render() {
        return (<Badge
            style={{textTransform: 'none'}} //className="text-capitalize"
            color="primary" > {/*pill*/}
            {this.props.label.beautify()
            } {this.labelToEmoji[this.props.label]}
        </Badge>);
    }
}

export default LabelBadge;