import React, {Suspense} from "react";

// core components
import CustomNavbar from "../components/Navbars/CustomNavbar.component.js";
import SimpleFooter from "../components/Footers/SimpleFooter.component.js";
import FallBackComponent from "../components/Fallback/FallBack.component"

// index page sections
import NoMatchContent from "../components/NoMatch/NoMatchContent.component"
import NavbarBackground from "../components/Navbars/NavbarBackground.component";


class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Suspense fallback={FallBackComponent}>
          <CustomNavbar />
            <NavbarBackground />
            <main ref="main">
              <NoMatchContent />
            </main>
          <SimpleFooter />
          </Suspense>
      </>
    );
  }
}

export default Index;
