import React from "react";
import classnames from "classnames";
// reactstrap components
import {
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane
} from "reactstrap";
import Comment from "./Comment.component";
import { withTranslation } from "react-i18next";

// eslint-disable-next-line
String.prototype.replaceAt = function(index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};
// eslint-disable-next-line
String.prototype.beautify = function () {
    return this.split("_").join(" ").replaceAt(0, this.charAt(0).toUpperCase());
};

class CommentsList extends React.Component {

    static defaultProps = {
        bigText: false
    };

    state = {
        tabs: 0,
        tasks: [
            {
                name: "sentiment",
                displayName: "Sentiment",
                labels: ["negative", "positive", "neutral"],
                tabs: 0
            },
            {
                name: "stance",
                displayName: "Stance",
                labels: ["for", "against", "discuss"],
                tabs: 0
            },
            {
                name: "topic",
                displayName: "Topic",
                labels: ["foreign_policy",
                    "economics_and_finance",
                    "law_and_order", "migration",
                    "environment",
                    "social_issues",
                    "parties_and_politics"],
                tabs: 0
            },
            {
                name: "style",
                displayName: "Style",
                labels: ["subjective", "factual", "personal", "sarcastic"],
                tabs: 0
            },
            {
                name: "poliview",
                displayName: "Political view",
                labels: ["liberal", "conservative", "neither"],
                tabs: 0
            }
        ]
    };

    toggleNavs = (e, state, index) => {
        e.preventDefault();
        this.setState({
            [state]: index
        });
    };

    toggleInternalNavs = (e, taskIndex, labelIndex) => {
        e.preventDefault();
        const newState = this.state;
        newState.tasks[taskIndex].tabs = labelIndex;
        this.setState(newState);
    };

    render() {
        const { t }= this.props;
        const navStyle = {};
        if(this.props.bigText) {
            navStyle.fontSize = 28;
        }
        return (
            <>
                <span className="text-primary bt--20">
                    {t("commentsList.browse")}
                    <i className="ni ni-bold-down"/> </span>
                <div className="nav-wrapper mt-n1">
                    <Nav
                        className="nav-fill flex-column flex-md-row"
                        id="tabs-icons-text"
                        pills
                        role="tablist"
                    >
                        <NavItem>
                            <NavLink
                                aria-selected={this.state.tabs === 0}
                                className={classnames(this.props.bigText ? "mb-sm-3 mb-md-0 p-2" : "mb-sm-3 mb-md-0", {
                                    active: this.state.tabs === 0
                                })}
                                style={navStyle}
                                onClick={e => this.toggleNavs(e, "tabs", 0)}
                                href="#all"
                                role="tab"
                            >
                                <i className="ni mr-2"/>
                                All
                            </NavLink>
                        </NavItem>
                        {this.state.tasks.map((task, index) => {
                            return (<NavItem>
                                    <NavLink
                                        aria-selected={this.state.tabs === index + 1}
                                        className={classnames(this.props.bigText ? "mb-sm-3 mb-md-0 p-2" : "mb-sm-3 mb-md-0", {
                                            active: this.state.tabs === index + 1
                                        })}
                                        style={navStyle}
                                        onClick={e => this.toggleNavs(e, "tabs", index + 1)}
                                        href={"#" + task.name}
                                        role="tab"
                                    >
                                        <i className="ni mr-2"/>
                                        {task.displayName}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                    </Nav>
                </div>
                <TabContent activeTab={"tabs" + this.state.tabs}>
                    <TabPane tabId="tabs0">
                        {this.props.comments.map((comment) => {
                            comment.component = (<Comment bigText={this.props.bigText}
                                                          comment={comment} taskNames={this.state.tasks.map((task) => task.name)}/>);
                            return comment.component;
                        })}
                    </TabPane>
                    {this.state.tasks.map((task, index) => {
                        return (<TabPane tabId={"tabs" + (index + 1)}>
                            <div className="nav-wrapper">
                                <Nav
                                    className="nav-fill flex-column flex-md-row"
                                    id={"tabs-icons-text" + task}
                                    pills
                                    role="tablist"
                                >
                                    {task.labels.map((label, labelIndex) => {
                                        const nCommentsLabelled = this.props.comments.filter(comment => comment[task.name + "_label"] === label).length;

                                        if(nCommentsLabelled) {
                                            return (<NavItem>
                                                    <NavLink
                                                        aria-selected={this.state.tasks[index].tabs === labelIndex}
                                                        className={classnames(this.props.bigText ? "mb-sm-3 mb-md-0 p-2" : "mb-sm-3 mb-md-0", {
                                                            active: this.state.tasks[index].tabs === labelIndex
                                                        })}
                                                        style={navStyle}
                                                        onClick={e => this.toggleInternalNavs(e, index, labelIndex)}
                                                        href={"#" + task.name + label}
                                                        role="tab"
                                                    >
                                                        <i className="ni mr-2"/>
                                                        {label.beautify()}
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        }
                                        return null;
                                    })
                                    }
                                </Nav>
                                <br/>
                                {task.labels.map((label, labelIndex) => {
                                    return (
                                    <TabContent activeTab={"tabs" + this.state.tasks[index].tabs}>
                                        <TabPane tabId={"tabs" + (labelIndex)}>
                                            {this.state.tabs === index + 1 ? this.props.comments
                                                    .filter(comment => comment[task.name + "_label"] === label)
                                                    .sort((com1, com2) => com2[task.name + "_max_pred"] - com1[task.name + "_max_pred"])
                                                .map((comment) => {
                                                        return comment.component ||
                                                         <Comment bigText={this.props.bigText}
                                                                   comment={comment}
                                                                   taskNames={this.state.tasks.map((task) => task.name)}/>;
                                                    }) : null}
                                        </TabPane>
                                    </TabContent>);
                                })}
                            </div>
                        </TabPane>
                    )
                        ;
                    })}
                </TabContent>
            </>
        );
    }
}

export default withTranslation("demo")(CommentsList);
