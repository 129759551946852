/*eslint-disable*/
import React, {Suspense} from "react";
import { useTranslation} from 'react-i18next';

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import uk_flag from '../../assets/img/countries/uk.png'
import it_flag from '../../assets/img/countries/it.png'

function ChangeLanguageComponent(){
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return <>
    <NavItem>
      <Button
        className="btn-inner-icon btn-footer-language" 
        onClick={() => changeLanguage('en')}>
      <img src={uk_flag} alt="en"/> 
      </Button>
    </NavItem>
    <NavItem>
      <Button 
      className="btn-footer-language"
      onClick={() => changeLanguage('it')}>
        <img src={it_flag} alt="it"/> 
      </Button>
    </NavItem>
  </>

}

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
      <Suspense fallback="Loading...">
        <footer className=" footer">
          <Container>
            <Row className=" align-items-center justify-content-md-between">
              <Col xs md lg="4">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href=""
                    target="_blank"
                  >
                    Artivon
                  </a>
                  .
                </div>
              </Col>
              <Col xs md lg="4">
                <Nav className="nav-footer justify-content-center">
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.linkedin.com/company/artivon/"
                      id="tooltip112445449"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin" />
                      {/*                       
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Linkedin
                      </span> 
                      */}
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip112445449">
                      Follow us on linkedin!
                    </UncontrolledTooltip>
                  </NavItem>
                  <ChangeLanguageComponent />
                </Nav>
              </Col>
              <Col xs md lg="4">
                <Nav className=" nav-footer justify-content-end">
                <NavItem>
                    <NavLink
                      href="/privacy"
                      target="_blank"
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/cookies"
                      target="_blank"
                    >
                      Cookies
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
        </Suspense>

      </>
    );
  }
}

export default SimpleFooter;
