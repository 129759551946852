import React from "react";

class NavbarBackground extends React.Component {
  render() {
    return (
      <>
        <section className="section pt-6 pb-2 section-shaped">
        <div className="shape shape-style-1 bg-gradient-info">
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
          {/*<span />*/}
        </div>
        </section>
      </>
    );
  }
}

export default NavbarBackground;