import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

import {
    Badge, Container, Row,
} from "reactstrap";

import toxic from '../../assets/img/emojis/toxic.png'
import approved from '../../assets/img/emojis/approved.png'
import custom from '../../assets/img/emojis/custom.png'

import positive from '../../assets/img/emojis/positive.png'
import neutral from '../../assets/img/emojis/neutral.png'
import negative from '../../assets/img/emojis/negative.png'
import agree from '../../assets/img/emojis/agree.png'
import disagree from '../../assets/img/emojis/disagree.png'
import discuss from '../../assets/img/emojis/discuss.png'
import foreign_policy from '../../assets/img/emojis/foreign_policy.png'
import economics_and_finance from '../../assets/img/emojis/economics_and_finance.png'
import law_and_order from '../../assets/img/emojis/law_and_order.png'
import migration from '../../assets/img/emojis/migration.png'
import environment from '../../assets/img/emojis/environment.png'
import social_issues from '../../assets/img/emojis/social_issues.png'
import parties_and_politics from '../../assets/img/emojis/parties_and_politics.png'
import subjective from '../../assets/img/emojis/subjective.png'
import personal from '../../assets/img/emojis/factual.png'
import factual from '../../assets/img/emojis/personal.png'
import sarcastic from '../../assets/img/emojis/sarcastic.png'
import liberal from '../../assets/img/emojis/liberal.png'
import conservative from '../../assets/img/emojis/conservative.png'
import neither from '../../assets/img/emojis/neither.png'

const labelToEmoji = {
    "toxic": toxic, 
    "approved": approved,
    "custom": custom,
    "positive": positive, "neutral": neutral, "negative": negative,
    "for": agree, "against": disagree, "observing": discuss,
    "agree": agree, "disagree": disagree, "discuss": discuss,
    "foreign_policy": foreign_policy, "economics_and_finance": economics_and_finance,
    "law_and_order": law_and_order, "migration": migration,
    "environment": environment, "social_issues": social_issues, "parties_and_politics": parties_and_politics,
    "subjective": subjective, "factual": factual, "personal": personal, "sarcastic": sarcastic,
    "liberal": liberal, "conservative": conservative, "neither": neither
};

const labelToToolTip = {
    "toxic": "Toxic, rejected", "approved": "Non-toxic, approved",
    "custom": "Any label you're interested in",
    "positive": "Positive sentiment",
    "neutral": "Neutral sentiment",
    "negative": "Negative sentiment",
    "for": "Agrees with the article",
    "against": "Disagrees with the article",
    "discuss": "Doesn't take a stance",
    "foreign_policy": "Topic: foreign policy",
    "economics_and_finance": "Topic: economics and finance",
    "law_and_order": "Topic: law and order",
    "migration": "Topic: migration",
    "environment": "Topic: environment",
    "social_issues": "Topic: social issues",
    "parties_and_politics": "Topic: parties and politics",
    "subjective": "Writing style: subjective",
    "factual": "Cites some facts",
    "personal": "Writing style: personal",
    "sarcastic": "Sarcastic",
    "liberal": "Liberal political view",
    "conservative": "Conservative political view",
    "neither": "No clear political view"
};

class LabelsDisplayer extends Component {
  render() {
    const { t } = this.props;
    return (
        <Container className={"p-0"}>
            {this.props.labels.map((emoji_key, index) =>
                <Row className="d-flex align-items-center p-0"
                    key={"labels_displayer_row_index_"+index}>
                <Badge className="badge-circle ml-n1"
                    style={{width: '3.2rem',
                        height: '3.2rem',
                        fontSize: '.875rem',
                        marginTop: '11px',
                        marginBottom: '11px',
                        marginRight: '11px'}}
                        color="white"
                    key={"labels_displayer_badge_index_"+index}
                    pill>
                    <img style={{
                        width: '1.8rem',
                        height: '1.8rem'}}
                        key={"labels_displayer_img_index_"+index}
                        src={labelToEmoji[emoji_key]} alt={emoji_key}/>
                </Badge>
                    <span className="text-white ml-n1" style={{fontSize: '25px'}}
                          key={"labels_displayer_span_index_"+index}
                    >
                        {t([`labelsDisplayer.tooltip.${emoji_key}`, labelToToolTip[emoji_key]])}
                    </span>
                </Row>
            )
            }
        </Container>
    );
  }
}

export default withTranslation("home")(LabelsDisplayer);
