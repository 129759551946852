import React from "react";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {
    Modal,
} from "reactstrap";

class AlertDismissableDemo extends React.Component {
    render() {
        const { t } = this.props;

        return (
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.props.isOpen}
                    toggle={this.props.handleClose}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Caveat: <strong> beta </strong> version
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.handleClose}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                      <p>{t("alertDismissableDemo.1")}
                      </p>
                    </div>
                </Modal>
        );
    }
}

export default withTranslation("demo")(AlertDismissableDemo);