import React from "react";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {
    Modal,
} from "reactstrap";

class DemoExplanationModal extends React.Component {
    render() {
        const { t } = this.props;

        return (
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.props.isOpen}
                    toggle={this.props.handleClose}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Demo explanation
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.props.handleClose}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {t("demoExplanationModal.1")}
                    <br/><br/>
                    {t("demoExplanationModal.2")}
                    <br/><br/>
                    <i>
                    {t("demoExplanationModal.3")}
                    </i> <br/><br/>
                    {t("demoExplanationModal.4")}
                    </div>
{/*                     <div className="modal-footer"> */}
{/*                         <Button */}
{/*                             color="secondary" */}
{/*                             data-dismiss="modal" */}
{/*                             type="button" */}
{/*                             onClick={() => this.toggleModal("exampleModal")} */}
{/*                         > */}
{/*                             Close */}
{/*                         </Button> */}
{/*                         <Button color="primary" type="button"> */}
{/*                             Save changes */}
{/*                         </Button> */}
{/*                     </div> */}
                </Modal>
        );
    }
}

export default withTranslation("demo")(DemoExplanationModal);